<template>
  <v-container>
    <v-card>
      <v-data-table class="rounded" :headers="headers" :items="communities" :search.sync="searchPhrase" @click:row="goToDetails" @pagination="testPager">
        <template v-slot:top>
          <v-toolbar flat rounded color="primary" dark>
            <v-toolbar-title>Communities</v-toolbar-title>
            <v-spacer />
            <v-text-field placeholder="Search" v-model="searchPhrase" prepend-inner-icon="mdi-magnify" hide-details filled rounded
              dense></v-text-field>
            <v-spacer />
            <v-btn text class="primary" :to="{ name: 'add-community' }" rounded>
              <v-icon left>mdi-plus</v-icon>
              Add Community
            </v-btn>
            <v-btn icon>
              <a :href="`${config.serverURL}/communities/download?token=${token}&factoryId=${(claims.factories || [])[0]}`">
              <!-- <a @click="downloadCommunitiesCSV"> -->
                <v-icon color="white">mdi-microsoft-excel</v-icon>
              </a>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- <template v-slot:item.details="{ item }">
          <v-btn
            @click.stop="disableFarmer(item)"
            :loading="item.disabling"
            color="error"
            depressed
            rounded
            block
            :outlined="!item.disabled"
          >
            {{ item.disabled ? "Disabled" : "Disable" }}
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import config from "@/config";
import firebase from "firebase";


dayjs.extend(relativeTime);
export default {
  name: "farmers",
  data: () => ({
    searchPhrase: "",
    disabling_farmer: false,
    headers: [
      //   { align: "center", text: "Community ID", value: "_id" },
      { align: "center", text: "Community", value: "name" },
      { align: "center", text: "Latitude", value: "latitude" },
      { align: "center", text: "Longitude", value: "longitude" },
      {
        align: "center",
        text: "Community Description",
        value: "description",
      },
    ],
    config,
    token: null,
    communities: [],
    downloading: false,
    
  }),
  
  methods: {
    getAge(date) {
      return dayjs(date).fromNow(true);
    },
    goToDetails(community, options) {
      this.$router.push({
        name: "edit-community",
        params: { id: community._id },
      });
    },
    async disableFarmer(item) {
      item.disabling = !item.disabling;
      this.$axios.put(`/communities/${item._id}`, { disabled: !item.disabled });
      item.disabled = !item.disabled;
      item.disabling = !item.disabling;
    },
    // async downloadCommunitiesCSV() {
    //   this.downloading = true;
    //   console.log(await this.$axios.get("/communities/download"));
    //   this.downloading = false;
    // },
    async downloadCommunitiesCSV() {
      this.downloading = true;
      const token = this.$firebase.auth().currentUser.getIdToken();
      await this.$axios.get("/communities/download", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      this.downloading = false;
    },
  },
  async mounted() {
    const { token, claims } = await firebase
      .auth()
      .currentUser.getIdTokenResult(true);
    this.token = token;
    this.claims = claims;

    this.$axios
      .get("/communities")
      .then((res) => {
        this.communities = (res.data || []).map((communities) => ({
          ...communities,
          disabling: false,
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  },
};
</script>
